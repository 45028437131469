import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../../constant";
import ApplicationHeaderAdmin from "./ApplicationHeaderAdmin";
import axios from 'axios';
import { tr } from "date-fns/locale";
import Store from "./../../../_helpers/store"
import { LOADING_ADMIN_APIS } from "./../../../_constants/constants"
import { toastr } from 'react-redux-toastr'

class VerifyIdentity extends Component {

  constructor(props) {
    super(props);
    this.state = {
      is_image_exists: true
    };
  }

  // Method to update the state to false
  updateImageExists = (status) => {
    this.setState({ is_image_exists: status });
  }

  downloadFile = (fileName) => {

    // loading started
    Store.dispatch((dispatch) => {
      dispatch({
        type: LOADING_ADMIN_APIS,
        status: true
      });
    });

    // Extract the file name from the provided fileName (if it includes a path)
    const extractedFileName = fileName.split('/').pop();

    // Make a GET request to the Django API endpoint with the complete file name as a parameter
    axios.get(`${API_URL}/download/${extractedFileName}`, {
      responseType: 'blob', // Set the response type to 'blob' to handle binary data
    })
      .then(response => {

        // Create a URL object from the binary data received
        const fileUrl = URL.createObjectURL(response.data);

        // Create an anchor tag and simulate a click to trigger the download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName; // Use the complete file name as the download attribute
        link.click();

        // Clean up the URL object
        URL.revokeObjectURL(fileUrl);

      })
      .catch(error => {
        console.error('Failed to download the file.', error);
      })
      .finally(() => {

        // loading finished
        Store.dispatch((dispatch) => {
          dispatch({
            type: LOADING_ADMIN_APIS,
            status: false
          });
        });
        
        toastr.success('Success!', 'File downloaded successfully.');

      }
      );

    

  };

  render() {

    function capitalizeString(string) {
      return string
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
    }

    function convertDateFormat(existingDate) {
      var date = new Date(existingDate);
      var options = { month: 'short', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    }

    function address(obj_persona_data) {

      const formattedAddress = [];

      if (Array.isArray(obj_persona_data) && obj_persona_data && obj_persona_data.length > 0) {
        const persona_data = obj_persona_data[0];
        if (persona_data.hasOwnProperty('address_street_1')) {
          formattedAddress.push(persona_data.address_street_1);
        }
        if (
          persona_data.hasOwnProperty('address_city') &&
          persona_data.hasOwnProperty('address_subdivision') &&
          persona_data.hasOwnProperty('address_postal_code')
        ) {
          formattedAddress.push(`${persona_data.address_city}, ${persona_data.address_subdivision} ${persona_data.address_postal_code}`);
        }
      }
      return formattedAddress.join(' ');
    }

    const get_image_url = (obj_persona_data, image_type) => {
      if (Array.isArray(obj_persona_data) && obj_persona_data.length > 0) {
        const persona_data = obj_persona_data[0];
        if (persona_data.hasOwnProperty(image_type)) {
          return `${API_URL}/${persona_data[image_type]}`;
        } else {
          this.updateImageExists(false);
        }
      }
      return '';
    }

    return (
      <React.Fragment>
        <div className="app-form-content">
          <div className="app-form-content-inner">
            <ApplicationHeaderAdmin {...this.props} />
            <div className="id-verif">
              <span className="request-detail" style={{ float: "left" }}>
                Identity Verification
              </span>

              <span
                className=""
                style={{ float: "right" }}
              >
                <button
                  className="btn-request-id"
                  onClick={() =>
                    this.props.request_identity_verification({
                      id: this.props.buyerAppId,
                      mail: true,
                      email_address: this.props.applicantEmail,
                      screen_name: "identity",
                      screen_link: `${window.location.origin}/buyer/my-application/${this.props.buyerAppId}/verify-identity`
                    })
                  }
                >
                  Request ID
                </button>
              </span>
            </div>


            <div className="applicant-info-main">
              <div className="Admin-PersonalInfo-Container co-applicant-detail-container">
                {this.props.license && (this.props.license || []).length > 0 ? (
                  <>
                    <div className="upload-doc-content">
                      {(this.props.license || []).map((item) => (
                        <div className="upload-doc-box">
                          <span className="icon-holder"></span>
                          <div className="text-box">
                            <h2> {item.license.split("/").pop() || ""} </h2>
                            <p> Verify Identity </p>
                            <button
                              className="upload-btn"
                              disabled={!item.license}
                              onClick={() =>
                                this.downloadFile(`${item.license}`)
                              }
                            >
                              {" "}
                              <span className=""> Download </span>{" "}
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  (this.props.jumio_data || []).length === 0 && (
                    <div className="id-verif-detail-head">
                      <>
                        <h1> NO IDENTITY HAS BEEN SUBMITED</h1>
                        <div className="text-center mt-5">
                          <button
                            className="btn-request-id"
                            onClick={() =>
                              this.props.request_identity_verification({
                                id: this.props.buyerAppId,
                                mail: true,
                                email_address: this.props.applicantEmail,
                                screen_name: "identity",
                                screen_link: `${window.location.origin}/buyer/my-application/${this.props.buyerAppId}/verify-identity`
                              })
                            }
                          >
                            Request ID
                          </button>
                        </div>
                      </>
                    </div>
                  )
                )}

                <div className="Admin-InfoInner2">
                  {(this.props.persona_data || []).length > 0 ? (
                    <>
                      {" "}

                      {(this.props.persona_data || []).map((item, index) => (
                        <>
                          <h1>General Information</h1>
                          <ul>
                            <li>
                              <div className="Admin-PrName">
                                <h2>Inquiry ID</h2>
                                <h3>
                                  {item?.inquiry_id ?? ""}
                                </h3>
                              </div>
                            </li>
                            <li>
                              <div className="Admin-PrName">
                                <h2>Created At</h2>
                                <h3>
                                  {(item?.created_at ?? "") && convertDateFormat(item.created_at)}
                                </h3>
                              </div>
                            </li>
                            <li>
                              <div className="Admin-PrName">
                                <h2>Status</h2>
                                <h3>
                                  {item?.status ?? ""}
                                </h3>
                              </div>
                            </li>
                          </ul>

                          <h1>User Data</h1>
                          <ul>
                            <li>
                              <div className="Admin-PrName">
                                <h2>Name</h2>
                                <h3>
                                  {(item?.first_name ?? "") && capitalizeString(item.first_name)}
                                  &nbsp;
                                  {(item?.last_name ?? "") && capitalizeString(item.last_name)}
                                </h3>
                              </div>
                            </li>

                            <li>
                              <div className="Admin-PrName">
                                <h2>Government ID Number</h2>
                                <h3>
                                  {item?.identification_number ?? ""}
                                </h3>
                              </div>
                            </li>

                            <li>
                              <div className="Admin-PrName">
                                <h2>Date of Birth</h2>
                                <h3>
                                  {(item?.birth_date ?? "") && convertDateFormat(item.birth_date)}
                                </h3>
                              </div>
                            </li>
                          </ul>

                          <ul>
                            <li>
                              <div className="Admin-PrName">
                                <h2>Address</h2>
                                <h3>
                                  <pre>{address(this.props.persona_data)}</pre>
                                </h3>
                              </div>
                            </li>

                            <li>
                              <div className="Admin-PrName">
                                <h2>Email Address</h2>
                                <h3>
                                  {item?.email_address ?? ""}
                                </h3>
                              </div>
                            </li>

                            <li>
                              <div className="Admin-PrName">
                                <h2>Phone Number</h2>
                                <h3>
                                  {item?.phone_number ?? ""}
                                </h3>
                              </div>
                            </li>
                          </ul>

                          <ul>
                            <li>
                              <div className="Admin-PrName">
                                <h2>Selected Country Code</h2>
                                <h3>
                                  {item?.country_code ?? "CA"}
                                </h3>
                              </div>
                            </li>

                            <li>
                              <div className="Admin-PrName">
                                <h2>Selected ID Class</h2>
                                <h3>
                                  {(item?.id_class ?? "").toUpperCase()}
                                </h3>
                              </div>
                            </li>
                          </ul>

                        </>
                      ))}
                    </>
                  ) : null}
                </div>

                {this.state.is_image_exists && (
                  <div className="Admin-InfoInner2">
                    <h1>Images</h1>
                    <div className="userDocAdmin">
                      <div className="userInfoOuterAdminMain">
                        <div className="userInfoOuterAdmin">
                          <h6>Selfie Image</h6>
                          <div className="imageuserAdmin">
                            <a
                              href={get_image_url(this.props.persona_data, 'selfie')}
                              target="_blank"><img src={get_image_url(this.props.persona_data, 'selfie')} />
                            </a>
                          </div>
                        </div>
                        <div className="userInfoOuterAdmin">
                          <h6>Document Image</h6>
                          <div className="imageuserAdmin">
                            <a
                              href={get_image_url(this.props.persona_data, 'document')}
                              target="_blank"><img src={get_image_url(this.props.persona_data, 'document')} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}


              </div>
              {(this.props.jumio_data || []).length !== 0 && (<div className="switch-holder">
                <input
                  id="swicth"
                  type="checkbox"
                  name="verify_identity_complete"
                  checked={this.props.verify_identity_complete}
                  onChange={(e) =>
                    this.props.changeCompleteStatus(
                      e,
                      "verify_identity_complete"
                    )
                  }
                />
                <label for="swicth" className="switch">
                  <div></div>
                </label>
                <span className="switch-label"> Complete </span>
              </div>)}

              <div className="footer-btns-holder clearfix">
                <Link to={`${this.props.url}/trade-in`}>                  {" "}
                  <button
                    className="btn btn-primary float-left"
                    onClick={() => this.props.onClickChangeStep(5, 4)}
                  >
                    {" "}
                    Back{" "}
                  </button>
                </Link>
                <Link to={`${this.props.url}/income-verify`}>
                  <button
                    className="btn btn-primary float-right active"
                    onClick={() => this.props.onClickChangeStep(5, 6)}
                  >
                    {" "}
                    Continue{" "}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default VerifyIdentity;