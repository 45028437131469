import React, { Component } from 'react';
import { history } from '../_helpers/history';
import Store from './store';
import * as ACTIONS from '../actions/authActions';

export default ComposedComponent => {

    class AdminAuthenticate extends Component {

        checkSession = async () => {
            try {
                // Validate the session using the action that sends a request to the backend
                const response = await Store.dispatch(ACTIONS.validate_session('admin'));

                if (response?.status === 200) {
                    // Session is valid, no further action needed
                    return;
                } else {
                    // If the session is invalid, clear the session and redirect to login
                    this.clearAdminSession();
                    history.push('/secure/admin-login');
                }
            } catch (error) {
                // Handle any errors (e.g., session validation failed)
                this.clearAdminSession();
                history.push('/secure/admin-login');
            }
        }
    
        componentDidMount() {
            // Check the session when the component is first mounted
            this.checkSession();
        }
    
        componentDidUpdate(prevProps) {
            // Check the session when the component updates
            if (this.props !== prevProps) {
            this.checkSession();
        }
        }

        clearAdminSession() {
            localStorage.removeItem('admin_user_type');
            localStorage.removeItem('admin');
            localStorage.removeItem('adminSccrens');
            localStorage.removeItem('lastAdminStepPostAppEdit');
            localStorage.removeItem('coAdminApplicantEditPostApp');
            // No need to manually remove cookies since they are HttpOnly
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    return AdminAuthenticate;
};
