import React, { Component } from 'react';
import { history } from '../_helpers/history';
import Store from './store';
import * as ACTIONS from '../actions/authActions';

export default ComposedComponent => {

    class AdminAuthenticate extends Component {

        checkSession = async () => {
            try {
                // Validate the session using the action that sends a request to the backend
                const response = await Store.dispatch(ACTIONS.validate_session('client'));

                if (response?.status === 200) {
                    // Session is valid, no further action needed
                    return;
                } else {
                    // If the session is invalid, clear the session and redirect to login
                    this.clearSession();
                    history.push('/login');
                }
            } catch (error) {
                // Handle any errors (e.g., session validation failed)
                this.clearSession();
                history.push('/login');
            }
        }
    
        componentDidMount() {
            this.checkSession();
        }
    
        componentDidUpdate(prevProps) {
            if (this.props !== prevProps) {
            this.checkSession();
        }
        }

        clearSession() {
            localStorage.removeItem('user');
            localStorage.removeItem('user_type');
            localStorage.removeItem('lastStepPostAppEditAdmin');
            localStorage.removeItem('coApplicantEditPostAppAdmin');
            localStorage.removeItem('lastStepPostAppEdit');
            localStorage.removeItem('coApplicantEditPostApp');
            localStorage.removeItem('lastStepDealerPostAppEdit');
            localStorage.removeItem('coApplicantDealerEditPostApp');
            // No need to manually remove cookies since they are HttpOnly
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    return AdminAuthenticate;
};
