import React, { useCallback, useState, useEffect } from 'react'
import SearchTable from '../../../../TableComponents/SearchTable';
import MultiSelectDropDown from '../../../../TableComponents/MultiSelectDropDown';
import DateFilter from '../../../../TableComponents/DateFilter';
import { retail_program_application_status } from '../../../../../constants';
import { useSelector } from 'react-redux';
import moment from 'moment';

const TableFilter = ({ search, setSearchParams, searchParams, setChangeSelect, changeSelect, setSearch }) => {
    const { agent_listing, assigned_dealers } = useSelector(({ adminReducer }) => {
        return {
            agent_listing: adminReducer.adminAccounts.applicationReducer.agent_listing,
            assigned_dealers: adminReducer.adminAccounts.applicationReducer.assigned_dealers
        }
    })

    // Needs to keep below params:
    // a) Agent
    // b) Application Status
    // c) Dealer
    // d) Search box
    // e) Filter by date.

    const [show, setShow] = useState(false);
    const [selectApplicationStatus, setApplicationStatus] = useState([])
    const [selectedAgent, setSelectedAgent] = useState([])
    const [selectedDealer, setSelectedDealer] = useState(null)
    const handleClose = useCallback(() => { setShow(false) }, []);
    const handleShow = useCallback(() => { setShow(true) }, []);

    /** Date Filter */
    const handleOnDone = (startDate, endDate) => {
        setSearchParams({
            ...searchParams,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
        })
        setChangeSelect(!changeSelect)
        handleClose()
    }

    useEffect(() => {

        let data = {}
        delete searchParams['application_status']
        delete searchParams['selected_agent']
        delete searchParams['created_by']

        if (selectApplicationStatus?.map(item => { return item.value })?.length > 0) {
            data.application_status = JSON.stringify(selectApplicationStatus?.map(item => { return item.value }) || []);
        }

        if (selectedAgent?.map(item => { return item.label })?.length > 0) {
            data.selected_agent = JSON.stringify(selectedAgent?.map(item => { return item.label }) || []);
        }

        setSearchParams({ ...searchParams, ...data })
        setChangeSelect(!changeSelect)

    }, [
        selectApplicationStatus,
        selectedAgent
    ])

    return (<>
        <p className="filtertxt" onClick={handleShow}><i className="fa fa-filter"></i > Filter by <span style={{ color: '#fb5100' }}>date</span></p>
        <div className="row">

            <div className='col-sm-2 selectdropdown'>
                <SearchTable search={search} handleOnChange={(e) => setSearch(e)} />
            </div>

            <div className="col-sm-2 selectdropdown">
                <MultiSelectDropDown dataOptions={retail_program_application_status} selectedValue={selectApplicationStatus}
                    setSelectedValue={setApplicationStatus}
                    placeHolder="Application Status" />
            </div>

            <div className="col-sm-2 selectdropdown">
                <MultiSelectDropDown dataOptions={[...(agent_listing || []).filter(item => item?.aud_user_id && item?.aud_user_id?.length > 0 && +item?.user_type === 5).map((item) => {
                    return {
                        value:
                            item?.aud_user_id &&
                                item?.aud_user_id?.length > 0
                                ? item?.aud_user_id?.[0]?.user_id || ""
                                : "",
                        label:
                            item?.aud_user_id &&
                                item?.aud_user_id?.length > 0
                                ? item?.aud_user_id?.[0]?.name || ""
                                : "",
                    };
                })]} selectedValue={selectedAgent}
                    setSelectedValue={setSelectedAgent}
                    placeHolder="Agent" />
            </div>

        </div>
        {show && <DateFilter handleClose={handleClose} show={show} handleOnDone={handleOnDone} />}
    </>)
}
export default (TableFilter)