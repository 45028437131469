import axios from "axios";
import { getMonth, getYear } from "date-fns";
import dateFormat from "dateformat";
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import { toastr } from "react-redux-toastr";
import Select, { components } from "react-select";
import MaskedInput from "react-text-mask";
import { API_URL } from "../../../../constant";
import ApplicationHeaderAdmin from "../ApplicationHeaderAdmin";

export class ServerValidation {
  validations = {};
  constructor(validations) {
    this.validations = validations;
  }
  getValidationAsString(key) {
    return key in this.validations && this.validations[key].length > 0
      ? this.validations[key].join("<br>")
      : null;
  }
  setValidations(validation) {
    this.validations = validation;
  }
}

export const scrollToTop = () => {
  document.querySelector('.app-form-main').scrollIntoView();
};

export const scrollToValidationError = () => {
  setTimeout(() => {
    const firstValidationErrorOnDom = document.querySelector('.text-danger:not(:empty)');
    if (firstValidationErrorOnDom) {
      firstValidationErrorOnDom.scrollIntoView({ block: 'center' });
    }
  }, 200);
};

const validateForm = (obj_validating_keys) => {
  return Object.values(obj_validating_keys).every(value => value !== false);
};

class PersonalAndEmploymentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenName: "personal-detail",
      availableEmploymentStatuses: [],
      serverValidations: new ServerValidation({}),
      userType: "",
      fieldsDisabled: window.location.href.includes("dealer-admin/retail-program-application")
    };
  }

  navigateToAssetDetail = (id) => {
    const { history } = this.props;
    const url = `/admin/applynow-application/${id}/assets-detail`;
    history.push(url);
  };

  componentDidMount() {
    const userType = localStorage.getItem("user_type");
    this.setState({ userType: parseInt(userType) });

    // Check URL hash and set the active tab accordingly
    const hash = window.location.hash;
    if (hash === "#personal_detail") {
      this.changeScreen("personal-detail");
    } else if (hash === "#employment_information") {
      this.changeScreen("employment-information");
    }

    setTimeout(() => {
      if (this.props.errors_employment_information_form.is_employment_information_form_validated) {
        this.changeScreen("employment-information");
      }
    }, 100);

    this.filterEmploymentStatuses();

  }

  extractDigits = (string = '') => {
    return string.replace(/\D/g, '');
  };

  changeScreen = (screenName) => {
    this.setState({ screenName });

    // Update the URL hash based on the selected tab
    if (screenName === "personal-detail") {
      window.location.hash = "#personal_detail";
    } else if (screenName === "employment-information") {
      window.location.hash = "#employment_information";
    }

    scrollToTop();
  };

  filterEmploymentStatuses = () => {
    const employmentStatuses = this.props.selectedEmploymentCategory.value === "Employed"
      ? this.props.employmentStatusesEmployed
      : this.props.employmentStatusesUnemployed;

    this.setState({ availableEmploymentStatuses: employmentStatuses });
  }

  componentDidUpdate(prevProps) {

    const { updateBuyerApplicationEmployement, updateBuyerApplicationPersonal, assetsDetail, onClickChangeStep, history, url } = this.props;

    if (prevProps.updateBuyerApplicationPersonal !== updateBuyerApplicationPersonal && updateBuyerApplicationPersonal !== undefined) {
      this.setState({ screenName: "employment-information" });
    }

    if (prevProps.updateBuyerApplicationEmployement !== updateBuyerApplicationEmployement && updateBuyerApplicationEmployement !== undefined) {
      if (assetsDetail === true) {
        onClickChangeStep(1, 2);
        history.push(`${url}/assets-detail`);
      } else {
        onClickChangeStep(1, 3);
        history.push(`${url}/delivery-verification`);
      }
    }

    if (prevProps.selectedEmploymentCategory.value !== this.props.selectedEmploymentCategory.value) {
      this.filterEmploymentStatuses();
    }

  }

  onBack = () => {
    this.setState({ screenName: "personal-detail" });
  };

  convert_to_string = (val) => {
    return val || "";
  };

  render() {
    const years = Array.from({ length: getYear(new Date()) - 1899 }, (_, i) => 1900 + i);
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const { Option } = components;

    const customStyles = {
      menu: (provided) => ({
        ...provided,
        height: 'auto', // Set the height to auto
        maxHeight: 'none', // Remove max height if necessary
      }),
      menuList: (provided) => ({
        ...provided,
        height: 'auto', // Set the height to auto
        maxHeight: 'none', // Remove max height if necessary
      }),
    };

    const renderScrollbar = (props) => (
      <Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={260}
      >
        {props.children}
      </Scrollbars>
    );

    return (
      <React.Fragment>
        <div className="app-form-content">
          <div className="app-form-content-inner">
            <ApplicationHeaderAdmin {...this.props} />
            <ul className="nav nav-tabs-2 admin-tabs three-items text-left" id="formAppTabs" role="tablist">
              <li className="nav-item" onClick={() => this.changeScreen("personal-detail")}>
                <a className={this.state.screenName === "personal-detail" ? "nav-link active pl-5" : "nav-link pl-5"} id="personal-detail-tab" data-toggle="tab" href="#personal-detail" role="tab" aria-controls="personal-detail" aria-selected={this.state.screenName === "personal-detail"}>
                  <span className="tabs-text personal-detail">Personal Detail</span>
                </a>
              </li>
              <li className={`nav-item`} onClick={() => {
                if (this.props.errors_personal_detail_form.is_personal_detail_form_validated) {
                  this.changeScreen("employment-information");
                } else {
                  alert('Please complete personal details');
                }
              }}>
                <a className={this.state.screenName === "employment-information" ? "nav-link active pl-5" : "nav-link pl-5"} href="#employment-information">
                  <span className="tabs-text employment-information">Employment Information</span>
                </a>
              </li>
            </ul>

            <div className="tab-content" id="formAppTabsContent">
              <div className={`tab-pane fade ${this.state.screenName === "personal-detail" ? "active show" : ""}`} id="personal-detail" role="tabpanel" aria-labelledby="personal-detail-tab">
                {this.state.screenName === "personal-detail" && (
                  <React.Fragment>
                    <div className="forms-head clearfix">
                      {(this.props.applicantFirstName || this.props.applicantLastName) && (
                        <h1 className="float-left">
                          {`${this.props.applicantFirstName || ""} ${this.props.applicantLastName || ""}${this.props.applicantFirstName ? "'s Details" : ""}`}
                        </h1>
                      )}
                    </div>
                    <div className="form-main">
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label>First Name<em>*</em></label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            name="applicantFirstName"
                            placeholder="First Name"
                            value={this.props.applicantFirstName}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantFirstName}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('applicantFirstName')}</span>
                        </div>
                        <div className="form-field-col">
                          <label>Last Name<em>*</em></label>
                          <input
                            type="text"
                            className="form-control"
                            name="applicantLastName"
                            placeholder="Last Name"
                            value={this.props.applicantLastName}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantLastName}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('applicantLastName')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label>Date of Birth<em>*</em></label>
                          <DatePicker
                            required
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} type="button">{"<"}</button>
                                <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                  {years.map((option) => (
                                    <option key={option} value={option}>{option}</option>
                                  ))}
                                </select>
                                <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                  {months.map((option) => (
                                    <option key={option} value={option}>{option}</option>
                                  ))}
                                </select>
                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} type="button">{">"}</button>
                              </div>
                            )}
                            selected={this.props.applicantDateOfBirth}
                            placeholderText="YYYY-MM-DD"
                            dateFormat="yyyy-MM-dd"
                            onChange={(e) => this.props.handleOnChangeDate(e, "applicantDateOfBirth")}
                            maxDate={new Date()}
                            name="applicantDateOfBirth"
                            className="form-control callendar"
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantDateOfBirth}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('applicantDateOfBirth')}</span>
                        </div>
                        <div className="form-field-col">
                          <label>Primary Phone<em>*</em></label>
                          <NumberFormat
                            required
                            className="form-control"
                            format="+1 (###) ###-####"
                            placeholder="+1 (123) 456-7890"
                            onChange={this.props.handleOnChangePersonalDetail}
                            value={this.props.applicantTelephone}
                            name="applicantTelephone"
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantTelephone}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('phone_number')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label>Email Address<em>*</em></label>
                          <input
                            type="email"
                            className="form-control"
                            name="applicantEmail"
                            placeholder="Email"
                            value={this.props.applicantEmail}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantEmail}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('email')}</span>
                        </div>
                      </div>
                      <h1 className="mt-5 pt-2" style={{ fontSize: "18px" }}>Current Address</h1>
                      <div className="form-field-row clearfix">
                        <div className="form-field-col">
                          <label>Street Address<em>*</em></label>
                          <input
                            type="text"
                            className="form-control"
                            name="applicantStreetAddress"
                            placeholder="Street Address"
                            value={this.props.applicantStreetAddress}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantStreetAddress}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('address')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label>City<em>*</em></label>
                          <input
                            required
                            type="text"
                            id="applicantCity"
                            name="applicantCity"
                            placeholder="City"
                            className="form-control"
                            onChange={this.props.handleOnChangePersonalDetail}
                            value={this.props.applicantCity}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantCity}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('city')}</span>
                        </div>
                        <div className="form-field-col selectSalutation">
                          <label>Province<em>*</em></label>
                          {this.state.fieldsDisabled ? (
                            <input
                              required
                              type="text"
                              placeholder="City"
                              className="form-control"
                              value={this.props.provinces.find(item => item.value === this.props.applicantProvince)?.label}
                              disabled={this.state.fieldsDisabled}
                            />
                          ) : (
                            <Select
                              required
                              placeholder="Select Province"
                              id="selectedProvince"
                              name="applicantProvince"
                              options={this.props.provinces}
                              onChange={(e) => this.props.changeSelect(e, "applicantProvince", "selectedProvince")}
                              value={this.props.selectedProvince}
                              className="react-select-main"
                              classNamePrefix="react-select"
                              components={{ Option, MenuList: renderScrollbar }}
                              captureMenuScroll={false}
                            />
                          )}
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantProvince}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('province')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label>Postal Code<em>*</em></label>
                          <MaskedInput
                            mask={[/[a-zA-Z0-9]/i, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/i, " ", /[a-zA-Z0-9]/, /[a-zA-Z0-9]/i, /[a-zA-Z0-9]/]}
                            className="form-control"
                            guide={false}
                            placeholder="12345"
                            id="applicantPostalCode"
                            name="applicantPostalCode"
                            value={this.props.applicantPostalCode}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantPostalCode}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('postal_code')}</span>
                        </div>
                        <div className="form-field-col">
                          <label>Country<em>*</em></label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="applicantCountry"
                            name="applicantCountry"
                            placeholder="Country"
                            value={this.props.applicantCountry}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantCountry}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('country')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label>Length of Stay at Current Address<em>*</em></label>
                          {this.state.fieldsDisabled ? (
                            <input
                              required
                              type="text"
                              placeholder="City"
                              className="form-control"
                              value={this.props.residentiallengthObj.find(item => item.value === this.props.duration_address_yr)?.label}
                              disabled={this.state.fieldsDisabled}
                            />
                          ) : (
                            <Select
                              required
                              id="selectLengthOfStayAtCurrentAddress"
                              name="duration_address_yr"
                              placeholder="Select Residential Length"
                              options={this.props.residentiallengthObj}
                              onChange={(e) => this.props.changeSelect(e, "duration_address_yr", "selectLengthOfStayAtCurrentAddress")}
                              value={this.props.selectLengthOfStayAtCurrentAddress}
                              className="react-select-main"
                              classNamePrefix="react-select"
                              components={{ Option, MenuList: renderScrollbar }}
                              captureMenuScroll={false}
                            />
                          )}
                          <span className='text-danger'>{this.props.errors_personal_detail_form.duration_address_yr}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('years_at_current_address')}</span>
                        </div>
                        <div className="form-field-col selectSalutation">
                          <label>Residential Status<em>*</em></label>
                          {this.state.fieldsDisabled ? (
                            <input
                              required
                              type="text"
                              placeholder="City"
                              className="form-control"
                              value={this.props.status}
                              disabled={this.state.fieldsDisabled}
                            />
                          ) : (
                            <Select
                              required
                              placeholder="Select Status"
                              id="selectedStatus"
                              name="status"
                              options={this.props.residentialStatuses}
                              onChange={(e) => this.props.changeSelect(e, "status", "selectedStatus")}
                              value={this.props.selectedStatus}
                              className="react-select-main"
                              classNamePrefix="react-select"
                              components={{ Option, MenuList: renderScrollbar }}
                              captureMenuScroll={false}
                            />
                          )}
                          <span className='text-danger'>{this.props.errors_personal_detail_form.status}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('do_you_own_or_rent')}</span>
                        </div>
                      </div>
                    </div>
                    <div className="footer-btns-holder clearfix">
                      {this.props.loading_update === true ? (
                        <button className="btn btn-primary float-right active">
                          <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                        </button>
                      ) : (
                        !this.state.fieldsDisabled ? (
                          <button
                            className="btn btn-primary float-right active"
                            onClick={(event) => {
                              scrollToValidationError();
                              event.preventDefault();
                              this.props.validateOnChangePersonalDetail('applicantFirstName', this.props.applicantFirstName);
                              this.props.validateOnChangePersonalDetail('applicantLastName', this.props.applicantLastName);
                              this.props.validateOnChangePersonalDetail('applicantDateOfBirth', this.props.applicantDateOfBirth);
                              this.props.validateOnChangePersonalDetail('applicantTelephone', this.props.applicantTelephone);
                              this.props.validateOnChangePersonalDetail('applicantEmail', this.props.applicantEmail);
                              this.props.validateOnChangePersonalDetail('applicantStreetAddress', this.props.applicantStreetAddress);
                              this.props.validateOnChangePersonalDetail('applicantCity', this.props.applicantCity);
                              this.props.validateOnChangePersonalDetail('applicantProvince', this.props.applicantProvince);
                              this.props.validateOnChangePersonalDetail('applicantPostalCode', this.props.applicantPostalCode);
                              this.props.validateOnChangePersonalDetail('applicantCountry', this.props.applicantCountry);
                              this.props.validateOnChangePersonalDetail('duration_address_yr', this.props.duration_address_yr);
                              this.props.validateOnChangePersonalDetail('status', this.props.status);
                              this.props.validateOnChangePersonalDetail('mortgage_amount', this.props.mortgage_amount);
                              if (validateForm(this.props.validation_personal_detail_form)) {
                                const payload = {
                                  first_name: this.props.applicantFirstName,
                                  last_name: this.props.applicantLastName,
                                  date_of_birth: dateFormat(this.props.applicantDateOfBirth, "yyyy-mm-dd"),
                                  phone_number: this.extractDigits(this.props.applicantTelephone),
                                  email: this.props.applicantEmail,
                                  street_address: this.props.applicantStreetAddress,
                                  street_address2: this.props.applicantStreetAddress2,
                                  city: this.props.applicantCity,
                                  province: this.props.applicantProvince,
                                  postal_code: this.props.applicantPostalCode,
                                  country: this.props.applicantCountry,
                                  length_of_stay_at_current_address: this.props.duration_address_yr,
                                  mortgage_amount: this.props.mortgage_amount ? this.props.mortgage_amount : 0,
                                  residentail_status: this.props.status,
                                  application_status: this.props.applicationStatus,
                                  application_type: 5,
                                  form_identity: 1,
                                  is_just_validation: true,
                                  user: window.localStorage.getItem('userId'),
                                };
                                const applicationId = this.props.match.params.id;
                                axios.put(`${API_URL}/applynow-applications/application/${applicationId}/update-personal-and-employment-detail`, payload).then(res => {
                                  toastr.success("Success", "Details successfully updated");
                                  this.changeScreen("employment-information");
                                });
                              } else {
                                this.props.userType === 2 ?
                                  this.props.errors_personal_detail_form.is_personal_detail_form_validated = false
                                  :
                                  this.props.errors_personal_detail_form.is_personal_detail_form_validated = true;
                              }
                            }}
                          >
                            Save & Continue
                          </button>
                        ) : (
                          <button className="btn btn-primary float-right active" onClick={() => this.changeScreen("employment-information")}>
                            Continue
                          </button>
                        )
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>

              <div className={`tab-pane fade ${this.state.screenName === "employment-information" ? "active show" : ""}`} id="employment-information" role="tabpanel" aria-labelledby="employment-information-tab">
                {this.state.screenName === "employment-information" && (
                  <React.Fragment>
                    <div className="forms-head clearfix">
                      <h1 className="">Employment Information</h1>
                    </div>
                    <div className="form-main">
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label>Employment Category<em>*</em></label>
                          {this.state.fieldsDisabled ? (
                            <input
                              required
                              type="text"
                              placeholder="City"
                              className="form-control"
                              value={this.props.employmentCategory}
                              disabled={this.state.fieldsDisabled}
                            />
                          ) : (
                            <Select
                              required
                              placeholder="Select Employment Category"
                              id="selectedEmploymentCategory"
                              name="employmentCategory"
                              options={this.props.employmentCategories}
                              onChange={(e) => this.props.changeSelect(e, "employmentCategory", "selectedEmploymentCategory")}
                              value={this.props.selectedEmploymentCategory}
                              className="react-select-main"
                              classNamePrefix="react-select"
                              components={{ Option, MenuList: renderScrollbar }}
                              captureMenuScroll={false}
                              styles={customStyles}
                            />
                          )}
                          <span className='text-danger'>{this.props.errors_employment_information_form.employmentCategory}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employement_category')}</span>
                        </div>
                        <div className="form-field-col selectSalutation">
                          <label>Employment Status<em>*</em></label>
                          {this.state.fieldsDisabled ? (
                            <input
                              required
                              type="text"
                              placeholder="City"
                              className="form-control"
                              value={this.props.employmentStatus}
                              disabled={this.state.fieldsDisabled}
                            />
                          ) : (
                            <Select
                              placeholder={this.props.employmentPlaceholder}
                              id="selectedEmploymentStatus"
                              name="employmentStatus"
                              options={this.state.availableEmploymentStatuses}
                              onChange={(e) => this.props.changeSelect(e, "employmentStatus", "selectedEmploymentStatus")}
                              value={this.props.selectedEmploymentStatus}
                              className="react-select-main"
                              classNamePrefix="react-select"
                              components={{ Option, MenuList: renderScrollbar }}
                              captureMenuScroll={false}
                              styles={customStyles}
                            />
                          )}
                          <span className='text-danger'>{this.props.errors_employment_information_form.employmentStatus}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employment_status')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label>Employer Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="employerName"
                            placeholder="Employee Name"
                            value={this.props.employerName}
                            onChange={this.props.handleOnChangeEmploymentInformation}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_employment_information_form.employerName}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employer_name')}</span>
                        </div>
                        <div className="form-field-col">
                          <label>Occupation</label>
                          <input
                            type="text"
                            className="form-control"
                            name="occupation"
                            placeholder="Occupation"
                            value={this.props.occupation}
                            onChange={this.props.handleOnChangeEmploymentInformation}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_employment_information_form.occupation}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('occupation')}</span>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label>Years with current employer<em>*</em></label>
                          {this.state.fieldsDisabled ? (
                            <input
                              required
                              type="text"
                              placeholder="City"
                              className="form-control"
                              value={this.props.yearsWithCurrentEmployerObj.find(item => item.value === this.props.yearsWithCurrentEmployer)?.label}
                              disabled={this.state.fieldsDisabled}
                            />
                          ) : (
                            <Select
                              required
                              placeholder="Select years with current employer"
                              id="selectYearsWithCurrentEmployer"
                              name="yearsWithCurrentEmployer"
                              options={this.props.yearsWithCurrentEmployerObj}
                              onChange={(e) => this.props.changeSelect(e, "yearsWithCurrentEmployer", "selectYearsWithCurrentEmployer")}
                              value={this.props.selectYearsWithCurrentEmployer}
                              className="react-select-main"
                              classNamePrefix="react-select"
                              components={{ Option, MenuList: renderScrollbar }}
                              captureMenuScroll={false}
                            />
                          )}
                          <span className='text-danger'>{this.props.errors_employment_information_form.yearsWithCurrentEmployer}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('years_with_employer')}</span>
                        </div>
                        <div className="form-field-row two-col clearfix">
                          <div className="form-field-col selectSalutation">
                            <label>Net Monthly Income<em>*</em></label>
                            <input
                              type="text"
                              className="form-control"
                              name="monthlyGrossIncome"
                              placeholder="monthlyGrossIncome"
                              value={this.props.monthlyGrossIncome}
                              onChange={this.props.handleOnChangeEmploymentInformation}
                              disabled={this.state.fieldsDisabled}
                            />
                            <span className='text-danger'>{this.props.errors_employment_information_form.monthlyGrossIncome}</span>
                            <span className='text-danger'>{this.state.serverValidations.getValidationAsString('monthly_income')}</span>
                          </div>
                          <div className="form-field-col"></div>
                        </div>
                      </div>
                      <div className="PostApp-Form mt-5">
                        <div className="py-2">
                          <h4>Employer Address</h4>
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label>Employer Province<em>*</em></label>
                          {this.state.fieldsDisabled ? (
                            <input
                              required
                              type="text"
                              placeholder="City"
                              className="form-control"
                              value={this.props.provinces.find(item => item.value === this.props.employerProvince)?.label}
                              disabled={this.state.fieldsDisabled}
                            />
                          ) : (
                            <Select
                              required
                              placeholder="Select Province"
                              id="selectedEmployerProvince"
                              name="selectedEmployerProvince"
                              options={this.props.provinces}
                              onChange={(e) => this.props.changeSelect(e, "employerProvince", "selectedEmployerProvince")}
                              value={this.props.selectedEmployerProvince}
                              className="react-select-main"
                              classNamePrefix="react-select"
                              components={{ Option, MenuList: renderScrollbar }}
                              captureMenuScroll={false}
                            />
                          )}
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employer_province')}</span>
                        </div>
                        <div className="form-field-col">
                          <label>Employer City</label>
                          <input
                            type="text"
                            id="employerCity"
                            name="employerCity"
                            className="form-control"
                            placeholder=""
                            value={this.props.employerCity}
                            onChange={this.props.handleOnChange}
                            disabled={this.state.fieldsDisabled}
                          />
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label>Employer Phone</label>
                          <NumberFormat
                            required
                            className="form-control"
                            value={this.props.employerPhone}
                            onChange={this.props.handleOnChange}
                            allowNegative={false}
                            id="employerPhone"
                            name="employerPhone"
                            format="+1-###-###-####"
                            placeholder="+1(123)456-7890"
                            disabled={this.state.fieldsDisabled}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="footer-btns-holder clearfix">
                      <button className="btn btn-primary float-left" onClick={this.onBack}>Back</button>
                      {this.props.loading_update === true ? (
                        <button className="btn btn-primary float-right active">
                          <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                        </button>
                      ) : (
                        !this.state.fieldsDisabled ? (
                          <button
                            className="btn btn-primary float-right active"
                            onClick={(event) => {
                              event.preventDefault();
                              scrollToValidationError();
                              this.props.validateOnChangeEmploymentInformation('employmentCategory', this.props.employmentCategory);
                              this.props.validateOnChangeEmploymentInformation('employmentStatus', this.props.employmentStatus);
                              this.props.validateOnChangeEmploymentInformation('yearsWithCurrentEmployer', this.props.yearsWithCurrentEmployer);
                              this.props.validateOnChangeEmploymentInformation('monthlyGrossIncome', this.props.monthlyGrossIncome);
                              this.props.validateOnChangeEmploymentInformation('employerStreetAddress', this.props.employerStreetAddress);
                              this.props.validateOnChangeEmploymentInformation('employerProvince', this.props.employerProvince);
                              this.props.validateOnChangeEmploymentInformation('employerEmail', this.props.employerEmail);
                              this.props.validateOnChangeEmploymentInformation('employerCity', this.props.employerCity);
                              this.props.validateOnChangeEmploymentInformation('employerPhone', this.props.employerPhone);
                              if (validateForm(this.props.validation_employment_information_form)) {
                                const payload = {
                                  employement_status: this.props.employmentCategory,
                                  type_of_employment: this.props.employmentStatus,
                                  employer_name: this.props.employerName || "",
                                  occupation: this.props.occupation || "",
                                  years_with_current_employer: this.props.yearsWithCurrentEmployer,
                                  gross_monthly_income: this.props.monthlyGrossIncome || '',
                                  employer_province: this.convert_to_string(this.props.employerProvince),
                                  employer_city: this.convert_to_string(this.props.employerCity),
                                  employer_telephone: this.convert_to_string(this.extractDigits(this.props.employerPhone)),
                                  application_status: this.props.applicationStatus,
                                  application_type: 5,
                                  form_identity: 2,
                                  is_just_validation: true
                                };
                                const applicationId = this.props.match.params.id;
                                axios.put(`${API_URL}/applynow-applications/application/${applicationId}/update-personal-and-employment-detail`, payload).then(res => {
                                  toastr.success("", "Details successfully updated");
                                  this.navigateToAssetDetail(applicationId);
                                });
                              } else {
                                this.props.errors_employment_information_form.is_employment_information_form_validated = false;
                              }
                            }}
                          >
                            Save & Continue
                          </button>
                        ) : (
                          <button className="btn btn-primary float-right active" onClick={() => this.navigateToAssetDetail(this.props.match.params.id)}>
                            Continue
                          </button>
                        )
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PersonalAndEmploymentDetail;
