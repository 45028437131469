import React, { Component } from 'react'
import {
   get_credit_details
} from '../../../../actions/admin/applicationActions'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';


class CreditReportDetail extends Component {
   constructor(props) {
      super(props);
      this.state = {
         credit_detail: []
      }
   }
   componentDidMount() {
      const id = this.props.match.params.id;
      this.props.get_credit_details(id)
   }
   componentDidUpdate(prevProps, prevState) {
      if (prevProps.application_credit_detail !== this.props.application_credit_detail && this.props.application_credit_detail) {
         this.setState({
            ...this.state,
            credit_detail: this.props.application_credit_detail
         })
      }
   }
   render() {
      const creditScore = this.state.credit_detail?.credit?.[0]?.score ?? 'N/A';
      return (
         <React.Fragment>
            <Helmet>
               <title>Credit Report</title>
               <meta name="description" content="" />
            </Helmet>
            <div className='Main-Credit-Report' ref={el => (this.componentRef = el)}>
               <div className="CreditReport-Container">
                  <div className="Admin-CreditReport">
                     {creditScore && creditScore !== 'N/A' ? (
                     <div className="CreditLeft">
                        <h1>{`${this.state.credit_detail?.names?.[0]?.first_name ?? ''} ${this.state.credit_detail?.names?.[0]?.middle_name ?? ''} ${this.state.credit_detail?.names?.[0]?.last_name ?? ''}`} - Credit Report</h1>
                     </div>
                     ) : <div className="CreditLeft"><h5>Unable to fetch the credit report.</h5></div>}
                     <div className="CreditRight">
                        <button><img src="image/sprite-icon/icon-print.svg" alt="" /></button>
                        <h2>CREDIT SCORE: <span>{creditScore}</span></h2>
                     </div>
                  </div>
               </div>

               <div className="Admin-PersonalInfo-Container">
                  {creditScore && creditScore !== 'N/A' ? (
                     <div className="Admin-InfoInner">

                        <div className="credit-report-print clearfix">
                           <ReactToPrint content={() => this.componentRef}>
                              <PrintContextConsumer>
                                 {({ handlePrint }) => (
                                    <button className="btn btn-primary" onClick={handlePrint}>Print Report!</button>
                                 )}
                              </PrintContextConsumer>
                           </ReactToPrint>

                        </div>

                        <h2 className='headingSubCredit'>General Detail</h2>
                        <table className='tableCreditReport'>
                           <thead>
                              <tr>
                                 <th style={{ width: '25%' }}>SIN</th>
                                 <th style={{ width: '25%' }}>DOB</th>
                                 <th style={{ width: '25%' }}>On File Date</th>
                                 <th style={{ width: '25%' }}>Telephone</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td>{this.state.credit_detail?.personal_information?.sin || ''}</td>
                                 <td>{this.state.credit_detail?.personal_information?.dob || ''}</td>
                                 <td>{this.state.credit_detail?.personal_information?.on_file_date || ''}</td>
                                 <td>{this.state.credit_detail?.personal_information?.telephone || ''}</td>
                              </tr>
                           </tbody>
                        </table>

                        <h2 className='headingSubCredit'>Names</h2>
                        <table className='tableCreditReport'>
                           <thead>
                              <tr>
                                 <th style={{ width: '25%' }}>Name Type</th>
                                 <th style={{ width: '25%' }}>Last Name</th>
                                 <th style={{ width: '25%' }}>First Name</th>
                                 <th style={{ width: '25%' }}>Middle Name</th>
                              </tr>
                           </thead>
                           <tbody>
                              {
                                 this.state.credit_detail?.names?.map((name, index) => (
                                    <tr key={index}>
                                       <td>{name.name_type}</td>
                                       <td>{name.last_name}</td>
                                       <td>{name.first_name}</td>
                                       <td>{name.middle_name || 'N/A'}</td>
                                    </tr>
                                 ))}
                           </tbody>
                        </table>


                        <h2 className='headingSubCredit'>Telephones</h2>
                        <table className='tableCreditReport'>
                           <thead>
                              <tr>
                                 <th style={{ width: '50%' }}>Phone Type</th>
                                 <th style={{ width: '50%' }}>Number</th>
                              </tr>
                           </thead>
                           <tbody>
                              {
                                 this.state.credit_detail?.telephones?.map((name, index) => (
                                    <tr key={index}>
                                       <td>{name.phone_type}</td>
                                       <td>{name.phone_number}</td>
                                    </tr>
                                 ))}
                           </tbody>
                        </table>

                        <h2 className='headingSubCredit'>Addresses</h2>
                        <table className='tableCreditReport'>
                           <thead>
                              <tr>
                                 <th style={{ width: '15%' }}>Address Type</th>
                                 <th style={{ width: '25%' }}>Street</th>
                                 <th style={{ width: '15%' }}>City</th>
                                 <th style={{ width: '15%' }}>Province</th>
                                 <th style={{ width: '15%' }}>Postal</th>
                                 <th style={{ width: '15%' }}>Date Reported</th>
                              </tr>
                           </thead>
                           <tbody>
                              {this.state.credit_detail?.addresses?.map((address, index) => (
                                 <tr key={index}>
                                    <td>{address.address_type}</td>
                                    <td>{address.street}</td>
                                    <td>{address.city}</td>
                                    <td>{address.province}</td>
                                    <td>{address.postal}</td>
                                    <td>{address.date_reported}</td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>

                        <h2 className='headingSubCredit'>Employments</h2>
                        <table className='tableCreditReport'>
                           <thead>
                              <tr>
                                 <th style={{ width: '30%' }}>Employer Name</th>
                                 <th style={{ width: '15%' }}>Occupation</th>
                                 <th style={{ width: '15%' }}>Date Hired</th>
                                 <th style={{ width: '15%' }}>Date Separated</th>
                                 <th style={{ width: '15%' }}>Date Verified</th>
                                 <th style={{ width: '5%' }}>Income</th>
                                 <th style={{ width: '5%' }}>Pay Basis</th>
                              </tr>
                           </thead>
                           <tbody>
                              {this.state.credit_detail?.employments?.map((employment, index) => (
                                 <tr key={index}>
                                    <td>{employment.employer_name}</td>
                                    <td>{employment.occupation}</td>
                                    <td>{employment.date_hired}</td>
                                    <td>{employment.date_separated}</td>
                                    <td>{employment.date_verified}</td>
                                    <td>{employment.income}</td>
                                    <td>{employment.pay_basis}</td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>

                     </div>
                  ) : ''}
               </div>

            </div>
         </React.Fragment >
      )
   }
}
const mapStateToProps = (state) => {
   return {
      application_credit_detail: state.adminReducer.adminAccounts.applicationReducer.application_credit_detail,
   }
}
export default connect(mapStateToProps, {
   get_credit_details
})(CreditReportDetail)

